import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  loading: false,
  error: null,
  data: {
    profiles: [],
    allAccesses: [],
    profileAccesses: [],
    allBrands: [],
    profileBrands: [],
    allDealers: [],
    profileDealers: [],
    status: "",
    message: "",
  },
};

export const updateProfile = createAsyncThunk(
  'profiles/updateProfile',
  async (profileData, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/admin/profile/${profileData.id}/update`, profileData);
      return response.data;
    } catch (error) {
      const errorResponse = error?.response?.data?.message || "Nombre de perfil ya existe!";
      return rejectWithValue(errorResponse);
    }
  }
);

const slice = createSlice({
  name: "profiles",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
      state.error = null;
    },
    profilesLoaded(state, action) {
      state.loading = false;
      if (Array.isArray(action.payload)) {
        state.data.profiles = action.payload.map((profile) => ({
          ...profile,
          key: profile.id,
        }));
      } else {
        state.error = "Invalid data format received.";
      }
    },
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    sendMessage(state, action) {
      state.loading = false;
      state.data.status = action.payload.status || "error";
      state.data.message = action.payload.message;
      state.error = action.payload.error || null;
    },
    resetMessage(state) {
      state.data.status = "";
      state.data.message = "";
      state.error = null;
    },
    allAccessesLoaded(state, action) {
      state.loading = false;
      state.data.allAccesses = action.payload;
    },
    profileAccessesLoaded(state, action) {
      state.loading = false;
      state.data.profileAccesses = action.payload;
    },
    accessError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    allBrandsLoaded(state, action) {
      state.loading = false;
      state.data.allBrands = action.payload;
    },
    profileBrandsLoaded(state, action) {
      state.loading = false;
      state.data.profileBrands = action.payload;
    },
    brandsError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    allDealersLoaded(state, action) {
      state.loading = false;
      state.data.allDealers = action.payload;
    },
    profileDealersLoaded(state, action) {
      state.loading = false;
      state.data.profileDealers = action.payload;
    },
    profileCreated(state, action) {
      state.loading = false;
      state.data.profiles.push(action.payload);
    },
    dealersError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    extraReducers: {
      [updateProfile.fulfilled]: (state, action) => {
        state.loading = false;
        const index = state.data.profiles.findIndex(
          (profile) => profile.id === action.payload.id
        );
        if (index !== -1) {
          state.data.profiles[index] = action.payload;
        }
        state.data.message = "Perfil actualizado con éxito";
      },
      [updateProfile.pending]: (state) => {
        state.loading = true;
      },
      [updateProfile.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },
    },
  },
});

export const {
  startLoading,
  profilesLoaded,
  hasError,
  sendMessage,
  resetMessage,
  allAccessesLoaded,
  profileAccessesLoaded,
  accessError,
  allBrandsLoaded,
  profileBrandsLoaded,
  brandsError,
  allDealersLoaded,
  profileDealersLoaded,
  dealersError,
} = slice.actions;

export default slice.reducer;

export function getProfiles(filters = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = "/admin/profile?";
      let count = 0;
      for (const property in filters) {
        url +=
          count === 0
            ? `${property}=${filters[property]}`
            : `&${property}=${filters[property]}`;
        count++;
      }
      const response = await axios.get(url);
      if (response.status === 204) {
        dispatch(slice.actions.profilesLoaded([]));
      } else if (response.data) {
        dispatch(slice.actions.profilesLoaded(response.data));
      } else {
        dispatch(slice.actions.hasError("Invalid response format."));
      }
    } catch (error) {
      dispatch(
        slice.actions.hasError(error.message || "Error fetching profiles.")
      );
    }
  };
}

export function createProfile(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = "/admin/profile/";
      const response = await axios.post(url, data);
      dispatch(slice.actions.profileCreated(response.data));
      return Promise.resolve(response.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Error al crear el perfil.";
      dispatch(slice.actions.hasError(errorMessage));
      return Promise.reject(new Error(errorMessage));
    }
  };
}

export const loadAllAccesses = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get("/access/list");
    dispatch(allAccessesLoaded(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error.toString()));
  }
};

export const loadProfileAccesses = (profileId) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.get(`/admin/profile/${profileId}/settings`);
    if (response && response.data && response.data.access) {
      dispatch(profileAccessesLoaded(response.data.access));
    } else {
      throw new Error("Invalid response format.");
    }
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const updateProfileAccesses =
  (profileId, accesses) => async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.post("/admin/access/settings/", {
        profileId,
        accesses,
      });
      if (response && response.data) {
        dispatch(loadProfileAccesses(profileId));
        return Promise.resolve(response.data);
      } else {
        return Promise.reject(new Error("Invalid response format."));
      }
    } catch (error) {
      dispatch(hasError(error.toString()));
      return Promise.reject(error);
    }
  };

export const loadAllBrands = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    let allBrands = [];
    let currentPage = 1;
    let pageTotal;

    do {
      const response = await axios.get(`/brands/all?page=${currentPage}`);
      if (response && response.data && Array.isArray(response.data.brands)) {
        allBrands = allBrands.concat(response.data.brands);
        pageTotal = response.data.pageTotal;
        currentPage++;
      } else {
        throw new Error("Invalid response format.");
      }
    } while (currentPage <= pageTotal);

    dispatch(allBrandsLoaded(allBrands));
  } catch (error) {
    dispatch(slice.actions.hasError(error.toString()));
  }
};
export const loadAllBrandsProfileModal = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    let allBrands = [];
    let currentPage = 1;
    let pageTotal;

    do {
      const response = await axios.get(`/brands/all?page=${currentPage}&count=${1000}`);
      if (response && response.data && Array.isArray(response.data.brands)) {
        allBrands = allBrands.concat(response.data.brands);
        pageTotal = response.data.pageTotal;
        currentPage++;
      } else {
        throw new Error("Invalid response format.");
      }
    } while (currentPage <= pageTotal);

    dispatch(allBrandsLoaded(allBrands));
  } catch (error) {
    dispatch(slice.actions.hasError(error.toString()));
  }
};

export const loadProfileBrands = (profileId) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.get(`/admin/profile/${profileId}/settings`);
    if (response && response.data && response.data.brands) {
      dispatch(profileBrandsLoaded(response.data.brands));
    } else {
      throw new Error("Invalid response format.");
    }
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const updateProfileBrands =
  (profileId, brandsToUpdate) => async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.post("/admin/brand/settings/", {
        profileId,
        brands: brandsToUpdate,
      });
      if (response && response.data) {
        dispatch(loadProfileBrands(profileId));
        return Promise.resolve(response.data);
      } else {
        throw Promise.reject(new Error("Invalid response format."));
      }
    } catch (error) {
      dispatch(hasError(error.toString()));
      return Promise.reject(error);
    }
  };

export const loadAllDealers = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    let allDealers = [];
    let currentPage = 1;
    let pageTotal;

    do {
      const response = await axios.get(`/dealers/all?page=${currentPage}`);
      if (response && response.data && Array.isArray(response.data.dealers)) {
        allDealers = allDealers.concat(response.data.dealers);
        pageTotal = response.data.pageTotal;
        currentPage++;
      } else {
        throw new Error("Invalid response format.");
      }
    } while (currentPage <= pageTotal);

    dispatch(allDealersLoaded(allDealers));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const loadAllDealersProfileModal = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    let allDealers = [];
    let currentPage = 1;
    let pageTotal;

    do {
      const response = await axios.get(`/dealers/all?page=${currentPage}&count=${1000}`);
      if (response && response.data && Array.isArray(response.data.dealers)) {
        allDealers = allDealers.concat(response.data.dealers);
        pageTotal = response.data.pageTotal;
        currentPage++;
      } else {
        throw new Error("Invalid response format.");
      }
    } while (currentPage <= pageTotal);

    dispatch(allDealersLoaded(allDealers));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const loadProfileDealers = (profileId) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.get(`/dealers/settings/${profileId}`);
    if (response && response.data) {
      dispatch(profileDealersLoaded(response.data));
    } else {
      throw new Error("Invalid response format.");
    }
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const updateProfileDealers =
  (profileId, dealersToUpdate) => async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.post("/dealers/settings", {
        profileId,
        dealers: dealersToUpdate,
      });
      if (response && response.data) {
        dispatch(loadProfileDealers(profileId));
        return Promise.resolve(response.data);
      } else {
        return Promise.reject(new Error("Invalid response format."));
      }
    } catch (error) {
      dispatch(hasError(error.toString()));
      return Promise.reject(error);
    }
  };
